"use client";
"use strict";
import React, { useState, useEffect } from 'react'
import { session } from '../../utils/AuthSession'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import DefaultVoucher from "../../assets/default-voucher.png"
import { Card } from "react-daisyui"
const Home = () => {
    const [authSession, setAuthSession] = useState(session())
    const [profile, setProfile] = useState(null)

    useEffect(() => {
        init();
    }, [])
    const init = async () => {
        const params = {
            access: 'auth',
            method: API.PROFILE.type,
            url: API.PROFILE.url,
            reqBody: {

            }

        }
        const response = await Api(params);
        if (response) {
            setProfile(response.data)
            console.log(response.data)

        }
    }

    return (
        <div className="h-screen w-full">
            <div className="h-screen mx-auto max-w-sm p-5 pt-8 pb-24">
                <div className='flex flex-col h-full overflow-auto no-scrollbar'>
                    <div className='bordered bg-white rounded-md  p-3 text-gray-600 text-center ml-10 mr-10'>
                        <div className='text-md font-semibold'>
                            Hallo, {profile?.firstname ? profile.firstname : authSession.user.phone}
                        </div>
                        <div className='text-xs'>
                            Point anda : 
                        </div>    
                        <div className="text-2xl font-bold">{profile?.point?.point ? profile.point.point : 0} point</div>
                    </div>
                    

                    
                    <div className='text-center'>Voucher anda:</div>
                    <div className='grid grid-cols-3'>
                        {profile?.member_voucher?.map((mv, i) => (
                            <div key={i} >
                                <img src={mv.voucher.voucher_image === null ? DefaultVoucher:mv.voucher.voucher_image} alt="" />
                            </div>
                        ))}
                        
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Home