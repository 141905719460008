import React from 'react';
import { products } from '../../jsons/products';
import Product from '../../components/Product';

const Transaction = () => {
    return (
        <>
            Transaction
            
        </>
    )
}

export default Transaction