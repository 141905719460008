import logo from './logo.svg';
import './App.css';
// import Home from './pages/Home';
// import About from './pages/About';
// import PageNotFound from "./pages/404";
import RootRouter from './components/RootRouter';

function App() {
    return  <RootRouter/>
    
        
}

export default App;
