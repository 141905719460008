import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import SocialButton from "../SocialButton";
import Logo from '../../assets/mic_logo.png'
import { navs } from '../../jsons/navs'
import { session, clearSession, setSession } from '../../utils/AuthSession'
import { gapi } from 'gapi-script';
import { GoogleLogin, GoogleLogout } from 'react-google-login'
import { Navigate } from 'react-router-dom'
import { AiFillHome, AiOutlineHistory, AiOutlineUser, AiOutlineDollarCircle, AiOutlineGift } from "react-icons/ai";
import {
    useNavigate
} from "react-router-dom";

// import Auth from '@/utils/auth'
// console.log('authSession',authSession)

const Navigation = (props) => {
    const [authSession, setAuthSession] = useState(session())
    const navigate = useNavigate();

    const handleOnSuccess = () => {
        setAuthSession(session())
        window.location.reload(false);
    }

    const handleLogout = () => {
        clearSession()
        setSession(session)
        window.location.reload(false);
    }

    return (
        <div className="fixed z-50 w-full h-16 min-w-lg -translate-x-1/2 bg-slate-700 border border-none bottom-0 left-1/2">
            <div className="grid h-full max-w-lg grid-cols-6 mx-auto">
                {navs.map((nav,i)=>(
                    <button key={i} type="button" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-600 group text-2xl text-white" 
                        onClick={()=> navigate(nav.link)}
                    >
                        {nav.icon}
                        <span className="sr-only">Home</span>
                    </button>
                ))}
            </div>
        </div>
    )
}

export default Navigation
