import React, { useState, useEffect, useRef } from 'react'
import { Avatar, Button } from 'react-daisyui'
import ProfilUnknow from '../../assets/profile-unknown.png'
import { session, clearSession } from '../../utils/AuthSession'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import { useNavigate } from 'react-router-dom'

function Profile() {
    const [authSession, setAuthSession] = useState(session())
    const [profile, setProfile] = useState(null)
    const [imgError, setImgError] = useState(false)
    const [profileImage, setProfileImage] = useState(null)
    const history = useNavigate()

    const ref = useRef()
    useEffect(() => {
        init();
    }, [])
    const init = async () => {
        const params = {
            access: 'auth',
            method: API.PROFILE.type,
            url: API.PROFILE.url,
            reqBody: {

            }

        }
        const response = await Api(params);
        if (response) {
            setProfile(response.data)
            setProfileImage(response.data.image_profile)
        }
    }

    const handleLogout = async () => {
        await clearSession()
        history(0)

    }

    const onError = (e) => {
        setProfileImage(ProfilUnknow)
    }

    return (
        <div className="h-screen w-full">
            <div className="h-screen mx-auto max-w-sm p-5 pt-8 pb-24">
                <div className='flex flex-col justify-between  h-full'>
                    <div>
                        <h1 className="text-xl font-semibold">Profile</h1>
                        <div className='flex gap-5 pt-5'>
                            <div className='w-1/2'>
                                <img src={profileImage} onError={onError} alt="profile" className='rounded-full'/>
                            </div>
                            <div>
                                <div className='font-semibold text-lg'>
                                    {profile?.firstname ? profile.firstname : 'John'} {profile?.lastname ? profile.lastname : 'Doe'}
                                </div>
                                <div>
                                    {profile?.phone}
                                </div>
                                <div>
                                    {profile?.email ? profile.email : 'belum mengisi email'}
                                </div>
                                <div className='pt-5'>
                                    <button className='btn normal-case bg-white text-slate-500 shadow-md shadow-slate-400 hover:bg-slate-400 border-none'>Perbaharui data</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='flex justify-end'>
                        <Button size="md" onClick={handleLogout}>Logout</Button>
                    </div>
                </div>





            </div>

        </div>
    )
}

export default Profile