import React, { useState, useEffect, useRef } from 'react'
import { Outlet,redirect, Navigate } from 'react-router-dom'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import { session, clearSession, setSession } from '../utils/AuthSession'

function MainLayout() {
    const [authSession, setAuthSession] = useState(session())

    useEffect(()=>{
    },[])

    if(authSession){
        return (
            <>
                <div className='flex flex-col h-screen justify-between'>
                    <Outlet />
                    <Navigation />
                </div>
            </>
        )
    }else{
        return (
            <Navigate to="/auth/login" replace={true} />
        )
    }
    
}

export default MainLayout