import React, { useState, useEffect, useRef } from 'react'
import { Button, Pagination, Card } from 'react-daisyui'
import ProfilUnknow from '../../assets/profile-unknown.png'
import { session } from '../../utils/AuthSession'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import moment from 'moment'
import DefaultLogo from "../../assets/default-logo.png"
import DefaultShop from "../../assets/coffee-shop.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Link, useParams  } from 'react-router-dom'

const CryptoJS = require("crypto-js");

function Voucher() {
    const [authSession, setAuthSession] = useState(session())
    const [voucher, setVoucher] = useState([])
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [data, setData] = useState()
    const ref = useRef()
    const ref2 = useRef()
    const [qrcode, setQrcode] = useState()

    let { id } = useParams();

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        const params = {
            access: 'auth',
            method: API.MERCHANT_DETAIL.type,
            url: API.MERCHANT_DETAIL.url,
            reqBody: {
                partner_id: id
            }
        }
        const response = await Api(params);

        console.log('data', response)
        if (response.status) {
            setData(response.data)
        }else{
            toast.error(response.message)
        }
    }


    

    return (
        <div className="h-screen w-full">
            <ToastContainer autoClose={1000} />
            <div className="h-screen mx-auto max-w-sm p-5 pt-8 pb-24">
                <div className='flex flex-col h-full'>
                    <h1 className="text-xl font-semibold">Detail Penyelenggara</h1>
                    <div className='overflow-auto no-scrollbar'>
                        <div className='flex gap-2 pt-5  m-2 items-center'>
                            <div className='w-1/5'>
                                <img src={data?.logo ? data?.logo : DefaultLogo} alt="logo" />
                            </div> 
                            <div className='font-semibold italic'>{data?.name}</div>  
                        </div>
                        <div className='p-5' dangerouslySetInnerHTML={{__html: data?.desc}} />
                        <div className='p-5' dangerouslySetInnerHTML={{__html: data?.howtogetpoint}} />
                        <div className='p-5'>
                            <div className='text-sm'>Tempat penukaran voucher:</div>
                            <div>
                                {data?.redeem_merchants?.map((v,i)=>(
                                    <div key={i} className='pt-5'>
                                        <div >
                                            <img src={v?.image ? v.image : DefaultShop} alt="" className='rounded-2xl'/>
                                        </div>
                                        
                                        <div>{v?.name}</div>
                                        <div>{v?.address}</div>

                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Voucher