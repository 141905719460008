import React, { useState, useEffect } from 'react'
import { Button, Pagination, Card } from 'react-daisyui'
import ProfilUnknow from '../../assets/profile-unknown.png'
import { session } from '../../utils/AuthSession'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import moment from 'moment'

function Profile() {
    const [authSession, setAuthSession] = useState(session())
    const [point, setPoint] = useState([])
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const [lastPage, setLastPage] = useState(1)


    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        const params = {
            access: 'auth',
            method: API.POINT.type,
            url: API.POINT.url,
            reqBody: {
                page: page,
                rows: 10
            }

        }
        const response = await Api(params);
        if (response) {
            setPoint(response.data.data)
            setLastPage(response.data.lastPage)
            setPage(response.data.page)
            setPages(toArray(response.data.page))
        }


    }

    const handlePrev = () => {
        setPage(page > 1 ? page - 1 : 1)
    }

    const handleNext = () => {
        setPage(page < lastPage ? page + 1 : lastPage)
    }

    useEffect(() => {
        init()
    }, [page])

    console.log('pages', pages)
    const toArray = (n) => {
        const a = []
        for (let i = 1; i <= n; i++) {
            a.push(i)
        }

        return a
    }


    return (
        <div className="h-screen w-full">
            <div className="h-screen mx-auto max-w-sm p-5 pt-8 pb-24">
                <div className='flex flex-col justify-between h-full'>
                    <h1 className="text-xl font-semibold">History</h1>
                    <div className='overflow-auto no-scrollbar'>
                        <div className='flex flex-col gap-2 pt-5  m-2'>
                            {point.map((p, i) => (
                                <div key={i} className='flex justify-between border rounded-md p-2 bg-white text-black'>
                                    <div >
                                        <div className='font-semibold'>{moment(p.created_at).format('DD/MM/YY')}</div>
                                        <div className='text-sm'>{p.desc}</div>
                                    </div>
                                    <div className='flex justify-center items-center text-xl'>{p.point}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='flex justify-between'>
                        <button className='btn' disabled={page === 1 ? true : false} onClick={handlePrev}>prev</button>
                        <button className='btn' disabled={page === lastPage ? true : false} onClick={handleNext}>next</button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Profile