export const API = {
    REQUEST_TOKEN: {
		url : 'member/auth/request/token',
		type: 'post',
	},
	LOGIN_TOKEN: {
		url: 'member/auth/login/token',
		type: 'post'
	},
	PROFILE: {
		url: 'member/profile',
		type: 'get'
	},
	POINT: {
		url: 'member/points',
		type: 'get'
	},

	VOUCHER: {
		url: 'member/vouchers',
		type: 'get'
	},

	REDEEM_VOUCHER: {
		url: "member/redeem/voucher",
		type: "get"
	},
	REDEEM: {
		url: "member/redeem",
		type: "post"
	},
	MERCHANT: {
		url: "member/partner",
		type: "get"
	},
	MERCHANT_DETAIL: {
		url: "member/partner/detail",
		type: "get"
	}

}